<template>
  <div>
    <div class="app-preview">
      <v-row class="receipt-action">
        <v-col
          cols="12"
          lg="3"
          class="mx-auto my-lg-auto"
        >
          <v-card>
            <v-card-text>
              <v-btn
                :disabled="receipt && receipt.deleted_at != null"
                color="ngBlue"
                block
                class="white--text"
                @click="printReceipt"
              >
                <v-icon left>
                  mdi-printer
                </v-icon>
                <span>Print</span>
              </v-btn>
              <v-btn
                v-if="receipt && receipt.insurance_policy_url"
                :disabled="receipt && receipt.deleted_at != null"
                color="ngBlue"
                block
                outlined
                class="mt-3"
                @click="isPolicyDialogVisible = true"
              >
                <v-icon left>
                  mdi-file-document-outline
                </v-icon>
                <span>View Policy</span>
              </v-btn>
              <v-btn
                v-if="receipt && receipt.payment_method.name === 'Visa Card' && receipt.visa_receipt_url"
                :disabled="receipt && receipt.deleted_at != null"
                color="ngBlue"
                block
                outlined
                class="mt-3"
                @click="isVisaReceiptDialogVisible = true"
              >
                <v-icon left>
                  mdi-paperclip
                </v-icon>
                <span>View Visa Receipt</span>
              </v-btn>
            </v-card-text>

            <v-card-text
              v-if="receipt && receipt.deleted_at"
              class="text-center"
            >
              <p class="error--text mb-0">
                This receipt has been voided.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="receipt-preview-card">
        <v-col
          cols="12"
          lg="6"
          class="mx-auto my-lg-auto"
        >
          <template>
            <v-card
              v-if="receipt"
              class="printable--theme mb-3 pt-5"
              :class="{ 'mobile-text' : $vuetify.breakpoint.xs }"
            >
              <div class="pb-2 px-5">
                <!-- Letterhead -->
                <Letterhead v-if="receipt.issuer.role !== 'Associate'" />
                <LetterheadAssociate v-else />
              </div>

              <v-divider class="printable-divider"></v-divider>

              <div class="px-5 px-sm-12 pt-2 pb-0">
                <v-row>
                  <!-- Customer Details -->
                  <v-col
                    cols="7"
                    sm="8"
                  >
                    <p class="me-2 printable-text font-weight-medium">
                      Date Issued: {{ receipt.issued_at }}
                    </p>
                    <p class="text-decoration-underline font-weight-medium payment-details-header mb-1">
                      Receipt From:
                    </p>
                    <p v-if="receipt.customer_type === 'Quoted' || receipt.customer_type === 'Staff rate'" class="mb-0">
                      {{ receipt.customer_name }} ({{ receipt.customer_contact_number }})
                    </p>
                    <p v-else class="mb-0">
                      {{ receipt.customer_name }}
                    </p>
                    <p class="mb-0">
                      {{ receipt.insurance.name }}
                    </p>
                    <p class="mb-0">
                      Insurance policy no.: {{ receipt.insurance_policy_number }}
                    </p>
                    <template v-if="receipt.insurance_product.name === 'Motor insurance'">
                      <p class="mb-0">
                        Car registration: {{ receipt.car_registration_number }}
                      </p>
                    </template>
                    <p class="mb-0">
                      Sum insured: {{ receipt.sum_insured }}
                    </p>
                    <template v-if="receipt.general_remarks !== null">
                      <p class="mb-0">
                        Remarks: {{ receipt.general_remarks }}
                      </p>
                    </template>
                    <p class="font-weight-medium mb-2">
                      Branch Issued: {{ receipt.branch.title }}
                    </p>
                    <p class="payment-details-header mb-0">
                      Payment By: {{ receipt.payment_method.name }}
                    </p>
                    <p
                      v-if="receipt.invoice_number"
                      class="mb-0"
                    >
                      for invoice <span class="ngPurple--text">{{ receipt.invoice_number }}</span>
                    </p>
                  </v-col>

                  <v-col>
                    <p
                      class="me-2 printable-text font-weight-medium"
                      :class="{ 'ml-n13': $vuetify.breakpoint.smAndUp, 'receipt-no-margin': $vuetify.breakpoint.xs }"
                    >
                      Official Receipt No:
                      <span class="ngPurple--text">{{ receipt.receipt_number }}</span>
                    </p>
                    <div>
                      <qrcode-vue
                        :value="receipt.record_preview"
                        level="L"
                      />
                      <p :class="{ 'ml-n4': $vuetify.breakpoint.smAndUp, 'scan-margin': $vuetify.breakpoint.xs }">Scan for authenticity</p>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <Details :record="receipt" />

              <template v-if="receipt.issuer.role !== 'Associate'">
                <v-divider class="printable-divider"></v-divider>
                <Branches />
              </template>
            </v-card>
          </template>
        </v-col>
        <div></div>
      </v-row>

      <!-- Insurance Policy dialog -->
      <v-dialog
        v-model="isPolicyDialogVisible"
        max-width="1000"
      >
        <v-card v-if="receipt">
          <v-card-title class="d-flex align-center py-3">
            {{ receipt.insurance_policy_name }}
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="ml-5"
              :href="receipt.insurance_policy_url"
              target="_blank"
            >
              <v-icon size="22">
                mdi-open-in-new
              </v-icon>
            </v-btn>
            <v-btn
              icon
              small
              class="ml-5"
              @click="isPolicyDialogVisible = false"
            >
              <v-icon size="22">
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <div>
            <iframe
              class="insurance-policy-viewer"
              :src="receipt.insurance_policy_url"
            ></iframe>
          </div>
        </v-card>
      </v-dialog>

      <!-- Visa Receipt dialog -->
      <v-dialog
        v-model="isVisaReceiptDialogVisible"
        max-width="1000"
      >
        <v-card v-if="receipt">
          <v-card-title class="d-flex align-center py-3">
            {{ receipt.visa_receipt_name }}
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="ml-5"
              :href="receipt.visa_receipt_url"
              target="_blank"
            >
              <v-icon size="22">
                mdi-open-in-new
              </v-icon>
            </v-btn>
            <v-btn
              icon
              small
              class="ml-5"
              @click="isVisaReceiptDialogVisible = false"
            >
              <v-icon size="22">
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <div>
            <iframe
              class="insurance-policy-viewer"
              :src="receipt.visa_receipt_url"
            ></iframe>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <v-row
        v-if="receipt && receipt.voucher_issued_code"
        class="voucher-preview mt-0"
      >
        <v-col
          cols="12"
          lg="6"
          class="mx-auto"
        >
          <VoucherPreview :voucher-code="receipt.voucher_issued_code" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import {
  Letterhead,
  LetterheadAssociate,
  Details,
  Branches,
} from '@/components/apps/previews'
import VoucherPreview from './VoucherPreview'

export default {
  name: 'receipt-preview',
  components: {
    Letterhead,
    LetterheadAssociate,
    Details,
    Branches,
    QrcodeVue,
    VoucherPreview,
  },
  data: () => ({
    receipt: null,
    isPolicyDialogVisible: false,
    isVisaReceiptDialogVisible: false,
  }),
  mounted() {
    this.fetchReceipt()
  },
  methods: {
    fetchReceipt() {
      this.$store
        .dispatch('fetchRecord', { record: 'receipts', preview_token: this.$route.params.preview_token })
        .then(response => {
          this.receipt = response.data.data
          this.onbeforeprint()
          this.onafterprint()
        })
        .catch((error) => {
          this.$router.push({name: 'Home', params: { message: error.response.data.message }})
        })
    },
    printReceipt() {
      window.print()
    },
    onbeforeprint() {
      window.addEventListener('beforeprint', (event) => {
        document.title = `receipt-${this.receipt.receipt_number}`
      })
    },
    onafterprint() {
      window.addEventListener('afterprint', (event) => {
        document.title = 'Niagamas Insurance Services Sdn. Bhd.'
      })
    }
  },
}
</script>

<style lang="scss">
@page {
  size: A4;
  margin: 0mm;
}

.app-preview {
  .payment-details-header {
    margin-bottom: 0.813rem;
  }
  .v-card.printable--theme {
    background-color: #fff !important;

    p {
      color: rgba(94, 86, 105, 0.87) !important;
    }

    .printable-divider {
      border-color: rgba(94, 86, 105, 0.14) !important;
    }
  }
  .purchased-items-table {
    th {
      background-color: transparent !important;
    }
  }
  .select-to {
    width: 14rem;
  }
  .printable--theme {
    p {
      font-size: 14px;
    }
  }
  .mobile-text {
    p {
      font-size: 9px;
    }
  }
  .receipt-preview-card,
  .receipt-action {
    margin: 0;
  }
}

.insurance-policy-viewer {
  height: 80vh;
  width: 100%;
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .receipt-action,
  .voucher-preview {
    display: none !important;
  }

  .app-preview {
    .printable--theme {
      border-radius: 0px;

      p {
        font-size: 14px;
      }

      .receipt-no-margin {
        margin-left: -52px !important;
      }

      .scan-margin {
        margin-left: -16px !important;
      }
    }

  }

  .app-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-preview {
    .receipt-header,
    .payment-details,
    .receipt-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
